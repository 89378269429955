import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/workspace/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/workspace/.nuxt/modules/@nuxt-scripts.mjs";
import plugin_vue3_A0OWXRrUgq from "/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/workspace/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/workspace/.nuxt/sentry-client-config.mjs";
import plugin_tbFNToZNim from "/workspace/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_GFfDXud5Cr from "/workspace/plugins/api.ts";
import maska_UHaKf2z1iQ from "/workspace/plugins/maska.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  _64nuxt_scripts_D34jXE4xNh,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_tbFNToZNim,
  chunk_reload_client_UciE0i6zes,
  api_GFfDXud5Cr,
  maska_UHaKf2z1iQ
]