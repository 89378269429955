import validate from "/workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import indicator_45global from "/workspace/middleware/indicator.global.ts";
import manifest_45route_45rule from "/workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  indicator_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "category-route-guard": () => import("/workspace/middleware/categoryRouteGuard.ts"),
  "device-repair-service": () => import("/workspace/middleware/deviceRepairService.ts"),
  "device-uri": () => import("/workspace/middleware/deviceUri.ts"),
  "problem-repair-services-by-repair-service-uri-id": () => import("/workspace/middleware/problemRepairServicesByRepairServiceUriId.ts"),
  "problems-route-guards": () => import("/workspace/middleware/problemsRouteGuards.ts"),
  "seo-links-by-problems-route-guard": () => import("/workspace/middleware/seoLinksByProblemsRouteGuard.ts"),
  "service-point-uri": () => import("/workspace/middleware/servicePointUri.ts"),
  "service-points-by-device-uri-id": () => import("/workspace/middleware/servicePointsByDeviceUriId.ts"),
  "services-list-guard": () => import("/workspace/middleware/servicesListGuard.ts")
}