import { getCityByLatLong } from '~/composables/endpoints/cities';
import { getDevices } from '~/composables/endpoints/devices';
import { getProblemDetails } from '~/composables/endpoints/problems';
import { getRepairServices } from '~/composables/endpoints/services';

const storeName = 'servicesStore';

const initState = {
  loadingStatus: 'init' as LoadingType,
  services: [] as RepairService[],
  page: 1,
  size: 100,
  orderBy: 'price' as ServicesOrderType,
  itemsCount: 0,
  hasNext: false,
  latitude: undefined as number | undefined,
  longitude: undefined as number | undefined,
  cityName: undefined as string | undefined,
  distance: 0 as number | undefined,
  userCity: undefined as City | undefined,
  userCityStatus: 'loading' as LoadingType,
  filters: [] as ServicesFiltersType[],
  problem: null as
    | {
        title: string;
        category: string;
      }
    | null
    | undefined,
};

export const useServicesStore = defineStore(storeName, {
  state: () => structuredClone(initState),
  getters: {
    getOrderOption(state) {
      return state.orderBy;
    },
    getHasNext(state) {
      return state.hasNext;
    },
    getItemsCount(state) {
      return state.itemsCount;
    },
    getLoadingStatus(state) {
      return state.loadingStatus;
    },
    getItems(state) {
      return state.services;
    },
    getCurrentCityName(state) {
      return state.cityName;
    },
    getSize(state) {
      return state.size;
    },
    getProblem(state) {
      return state.problem;
    },
    getCity(state) {
      const isAllCityDataProvided =
        state.latitude !== undefined &&
        state.longitude !== undefined &&
        state.cityName !== undefined;

      if (isAllCityDataProvided) {
        return {
          latitude: state.latitude,
          longitude: state.longitude,
          name: state.cityName,
        };
      }
      return undefined;
    },
    getQuery(state) {
      const {
        page,
        size,
        orderBy,
        longitude,
        distance,
        latitude,
        cityName,
        filters,
      } = state;

      const filtersQuery = JSON.stringify(filters);
      return {
        page,
        size,
        orderBy,
        longitude,
        latitude,
        distance,
        cityName,
        filters: filtersQuery,
      };
    },
    getCurrentPage(state) {
      return Number(state.page);
    },
  },
  actions: {
    async loadItems() {
      const route = useRoute();
      this.loadingStatus = 'loading';
      const { deviceId, problemId, category, manufacturerId } = route.params;
      const { query } = route;

      const filters = query.filters
        ? JSON.parse(decodeURIComponent(query.filters as string))
        : [];

      this.$state = { ...this.$state, ...query, filters };
      const queriesKeys = Object.keys(query);
      const isCityProvided =
        queriesKeys.includes('longitude') &&
        queriesKeys.includes('latitude') &&
        queriesKeys.includes('distance');
      if (!isCityProvided) {
        this.$state.latitude = undefined;
        this.$state.longitude = undefined;
        this.$state.cityName = undefined;
      }
      try {
        const deviceData = await getDevices({
          page: 1,
          size: 1,
          uri_id: deviceId as string,
          manufacturer_uri_id: manufacturerId as string,
          type: category as CategoryType,
        });
        const device = deviceData.results[0];

        const problemDetails = await getProblemDetails(device.uriId, problemId as string);
        const data = await getRepairServices({
          page: this.page,
          size: this.size,
          device: device.id,
          problem: problemDetails.id,
          order_by: this.orderBy,
          longitude: this.longitude,
          latitude: this.latitude,
          distance: this.distance,
          image_size: '840x840',
          authorized: this.filters.includes('authorized'),
          exact_price: this.filters.includes('exact_price'),
        });
        if (data.problem == null) {
          navigateTo('/not-found');
        }
        this.problem = data.problem ?? null;
        this.loadingStatus = 'idle';
        this.services = [...data.results];
        this.itemsCount = data.count;
        this.hasNext = !!data.next;

        const indicatorStore = useIndicatorStore();
        indicatorStore.defineIndicatorTitle(route);
      } catch (_) {
        this.loadingStatus = 'idle';
      }
    },
    tryToDetectCity() {
      this.userCityStatus = 'loading';
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getCityByLatLong(latitude, longitude).then(
            (data) => {
              if (data.id !== undefined) {
                this.userCity = data;
                this.userCityStatus = 'idle';
              }
            },
            (_) => {
              this.userCityStatus = 'failed';
            }
          );
        },
        () => {
          this.userCityStatus = 'failed';
        }
      );
    },
    reset() {
      const route = useRoute();
      const { query } = route;

      const filters = query.filters
        ? JSON.parse(decodeURIComponent(query.filters as string))
        : [];

      this.$state = { ...initState, ...query, filters };
    },
    clearProblem() {
      this.problem = null;
    },
    changeGroup(newGroupOption: ServicesOrderType) {
      if (newGroupOption !== this.orderBy) {
        this.orderBy = newGroupOption;
        this.services = [];
        this.applyQueries();
      }
    },
    changeDistance(newDistance: number) {
      if (newDistance !== this.distance) {
        this.distance = newDistance;
        if (
          this.cityName !== undefined &&
          this.cityName !== '' &&
          this.cityName !== null
        ) {
          this.applyQueries();
        }
      }
    },
    changePage(newPage: number) {
      if (newPage !== this.page) {
        this.page = newPage;
        this.applyQueries();
      }
    },
    changeSize(newSize: number) {
      if (newSize !== this.size) {
        this.size = newSize;
        this.applyQueries();
      }
    },
    changeCity(newCity: City | undefined) {
      const isSame =
        newCity?.latitude === this.latitude &&
        newCity?.longitude === this.longitude &&
        newCity?.name === this.cityName;
      if (!isSame) {
        this.latitude = newCity?.latitude;
        this.longitude = newCity?.longitude;
        this.cityName = newCity?.name;
        this.applyQueries();
      }
    },
    changeFilters(newFilters: ServicesFiltersType[]) {
      if (newFilters.length !== this.filters?.length) {
        this.filters = newFilters;
        this.applyQueries();
      }
    },
    async applyQueries() {
      this.loadingStatus = 'loading';
      const route = useRoute();
      const { query } = route;
      const newQuery = this.getQuery;

      await navigateTo({
        path: route.path,
        query: {
          ...query,
          ...newQuery,
        },
      });
      this.loadItems();
    },
  },
});
