import type { RouteLocationNormalized } from "#vue-router";
import getManufactures from "~/composables/endpoints/manufacturer";

const storeName = 'indicatorStore';

export const useIndicatorStore = defineStore(storeName, () => {

  const indicatorTitle = ref('');

  const defineIndicatorTitle = async (route: RouteLocationNormalized) => {
    const servicesStore = useServicesStore();
    const { name, params } = route;
    const { getItemsCount } = servicesStore;
    if (name !== 'category-manufacturerId-deviceId-index-problemId') {
      servicesStore.reset();
    }
    switch (name) {
      case 'index-index-category':
        indicatorTitle.value = 'Producenci';
        break;
      case 'index-index-category-manufacturerId': {
        if ((params.manufacturerId as string) != null) {
          const type = params.category as CategoryType;
          const manufacturerUriId = params.manufacturerId as string;
          const manufacturers = await getManufactures(type, '200x200', manufacturerUriId);
          const manufacturerName: string | undefined = manufacturers.find((manufacturer) => manufacturer.uriId === manufacturerUriId)?.name
          
          if (manufacturerName == null || manufacturerName === undefined) {
            navigateTo('/not-found');
          }
          
          indicatorTitle.value = `Urządzenia ${manufacturerName}`;
          return;
        }
        indicatorTitle.value = 'Urządzenia';
        break;
      }
      case 'category-manufacturerId-deviceId-index':
        indicatorTitle.value = 'Wybierz usterkę / usługę';
        break;
      case 'category-manufacturerId-deviceId-index-problemId':
        if (getItemsCount === 0) {
          indicatorTitle.value = 'Serwisy';
          return;
        }
        indicatorTitle.value = `Serwisy (${getItemsCount})`;
        break;
      default:
        indicatorTitle.value = '';
    }
  };
  return { indicatorTitle, defineIndicatorTitle };
});
