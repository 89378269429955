export function getProblemsCategories(deviceId: string) {
  const { $api } = useNuxtApp()
  return $api(`devices/${deviceId}/grouped-problems/`, {
    method: 'GET',
  });
}

export function getDetailedProblems(
  deviceCategory: CategoryType,
  params: GetProblemsParams
) {
  const { $api } = useNuxtApp()
  return $api<PaginatedResult<Problem>>(
    `/device-problems/${deviceCategory}/`,
    {
      method: 'GET',
      params,
    }
  );
}

export function getProblemDetails(deviceId: string, problemUrlId: string) {
  const { $api } = useNuxtApp()
  return $api<Problem>(`devices/${deviceId}/problem/${problemUrlId}/`, {
    method: 'GET',
  });
}
