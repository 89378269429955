import camelcaseKeys from 'camelcase-keys';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const api = $fetch.create({
    baseURL: config.public.apiBase as string,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': config.public.apiKey as string,
      'accept-language': 'pl-PL',
    },
    parseResponse(responseText) {
      return camelcaseKeys(JSON.parse(responseText), { deep: true });
    },
    onRequest({ options }) {
      const { params } = options;
      const { query } = options;
      const hasCategoryTypeInParams = params && (params.category || params.device_type || params.type)

      if (hasCategoryTypeInParams) {
        const categoryTypeToData: Record<string, any> = {
          smartfon: 'smartphone',
          konsola: 'console',
          laptop: 'notebook',
          tablet: 'tablet',
        };
        if (params && params.category) {
          params.category =
            categoryTypeToData[params.category] ?? params.category;
        }
        if (params && params.device_type) {
          params.device_type =
            categoryTypeToData[params.device_type] ?? params.device_type;
        }
        if (params && params.type) {
          params.type = categoryTypeToData[params.type] ?? params.type;
        }
      }
    },
  });

  return {
    provide: {
      api,
    },
  };
});
